body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 /* Style all font awesome icons */
 .fa {
  padding: 10px;
  font-size: 20px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
  background: #3B5998;
  color: white;
}

/* Twitter */
.fa-twitter {
  background: #55ACEE;
  color: white;
} 

/* Make the image fully responsive */
.carousel-inner img {
    width: 100%;
    height: 100%;
}

h1.display-4 {
  font-size: 3.0em;
  font-weight: 400;
}

.game-block {
  background-color: #000; 
  min-height:450px; 
  min-width: 340px; 
  padding-left: 15px;
}

.tic-tac-toe-links {
  background-color: #fff;
}

.nav-item {
  padding-right: 40px;
}