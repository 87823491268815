.card {
    background-color: #f0f0f0;
    padding-bottom: 30px;
    max-height: 350px;
    text-align:center;
    font-family: 'Didact Gothic', sans-serif;
    -webkit-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.49);
    -moz-box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.49);
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.49);
  }
  
  #temperature {
    font-size: 80px;
  }
  
  #conditions {
    margin-top: -40px;
  }
  
  #conditions-icon {
    font-size: 60px;
    padding-top: 20px;
  }
  
  #conditions-text {
      font-size: 24px;
      font-weight: 300;
  }
  
  #wind {
    font-size: 20px;
    font-weight: 300;
  }
  
  #wind-icon {
    font-size: 40px;
  }